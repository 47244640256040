<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="my-5">その他料金の設定</h1>
        <banner-hint>
          指名料金や入会金等の個別で発生する追加料金を設定したい場合に使用してください。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        v-if="items.length"
      >
        <v-card
          class="py-5 px-3"
          elevation="1"
        >
          <v-row no-gutters>
            <v-col cols="12" lg="6"
              class="mb-5 pt-3 px-5"
              v-for="(item, index) in items"
              :key="item.item_id"
            >
              <!-- 入力一列 -->
              <v-form :ref="'form-item-' + item.item_id">
                <v-row no-gutters>
                  <v-col cols="8"
                    class="px-1"
                  >
                    <v-text-field
                      v-model.trim="item.item_name"
                      required
                      label="項目名"
                      :rules="[valiRules.required, valiRules.max20]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4"
                    class="px-1"
                  >
                    <v-text-field
                      v-model.trim="item.item_charge"
                      type="number"
                      required
                      label="料金（円）"
                      :rules="[valiRules.required, valiRules.price]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      class="pt-2"
                      counter="50"
                      v-model="item.description"
                      :rules="[valiRules.max50]"
                      label="補足説明（必要であれば）"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    class="d-flex justify-end align-center pt-0"
                  >
                    <v-btn
                      v-if="item.create"
                      depressed small
                      color="accent"
                      @click="createRow(item)"
                    >登録</v-btn>
                    <v-btn
                      v-else
                      depressed small
                      color="primary"
                      @click="updateRow(item)"
                    >更新</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow(index)"
                    >削除</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- 要素追加ボタン -->
      <v-col cols="12"
        class="mb-5"
      >
        <v-btn
        depressed
        color="primary"
        @click="addBlankRow()"
        >補足料金を追加</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="!items.length" flat>
          <v-card-text>補足料金が登録されていません。</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { SITE_API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxId: 0,
      items: [],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getItems(),
      ])

      this.loading = false
    },

    //入力列追加
    addBlankRow() {
      if (this.items.length >= 10) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.items.push({
        item_id: ++this.maxId,
        item_name: '',
        item_charge: null,
        description: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //**************************************************
    getItems() {
      return this.publicApi.getReqSitePublic('miscellaneous-fee/').then( results => {
        if (!results || !results.length) return

        //id最大値の取得
        this.maxId = Math.max(...results.map( row => parseInt(row.item_id) ))

        this.items = results
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(item) {
      if (!this.$refs['form-item-' + item.item_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append("item_name", item.item_name);
      formData.append("item_charge", item.item_charge);
      formData.append("description", item.description);

      this.adminApi.apiReqWithData('POST', 'create/miscellaneous-fee/', formData).then( response => {
        item.item_id = response.item_id
        item.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(item) {
      if (!this.$refs['form-item-' + item.item_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/miscellaneous-fee/' + item.item_id
      const formData = new FormData();
      formData.append("item_name", item.item_name);
      formData.append("item_charge", item.item_charge);
      formData.append("description", item.description);

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.items[index].create) {
        this.items.splice(index, 1)
        return
      }

      const apiPath = 'delete/miscellaneous-fee/' + this.items[index].item_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.items.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  }
}
</script>

<style scoped>
>>> .v-input__slot {
  margin-bottom: 4px;
}
</style>
